import TYPES from '@/types';

import i18n from '@/vue-app/plugins/i18n';
import Vue from 'vue';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { Geolocation } from '@/modules/shared/domain/geolocation';

// Domain
import { ValidateWithdrawalWorkingHoursService } from '@/modules/my-investment/kuspit/withdrawal/domain/services';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class WithdrawalReminderViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.GEOLOCATION)
  readonly geolocation!: Geolocation;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  private readonly i18n_namespace = 'components.kuspit-dashboard.withdrawals.reminder';

  private readonly view!: Vue;

  is_loading = false;

  get user_name() {
    const { name } = this.get_person_query.execute();
    return name;
  }

  get is_working_hours() {
    return ValidateWithdrawalWorkingHoursService.validate();
  }

  get can_continue() {
    return this.is_working_hours && !this.is_loading;
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  getCoordinates = async () => {
    this.is_loading = true;
    try {
      const coordinates = await this.geolocation.getCurrentPosition();
      if (coordinates) {
        this.view.$emit('nextStep', coordinates.latitude.toString(), coordinates.longitude.toString());
      }
    } catch {
      const message = `${i18n.t(`${this.i18n_namespace}.error_get_location`)}`;
      this.messageNotifier.showErrorNotification(message);
    } finally {
      this.is_loading = false;
    }
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  nextStep = async () => {
    await this.getCoordinates();
  }

  emitLoading = (is_loading: boolean) => {
    this.view.$emit('loadingInfo', is_loading);
  }
}
